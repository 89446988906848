import Image from '@/ui/widgets/image'
import Loading from '@/ui/widgets/loading'
import { ImageProps } from 'next/dist/client/image'
import React, { FC, useState } from 'react'

const Placeholder: FC = () => (
  <div data-testid="placeholder" className="bg-white w-full h-full relative">
    <Loading className="!my-0 abs-center" />
  </div>
)

export const LoadingImage: FC<ImageProps> = ({ alt, ...props }) => {
  const [loading, setLoading] = useState<boolean>(true)

  return (
    <>
      {loading && <Placeholder />}
      <Image alt={alt} {...props} onLoadingComplete={() => setLoading(false)} onError={() => setLoading(false)} />
    </>
  )
}

export default Placeholder
