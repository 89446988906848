import { ABOUT_US_URL } from '@/constants/global'
import { RecommendCategory } from '@/models/products/product'
import { getProductsCategories } from '@/pages/api/products/product-category'
import Banner from '@/ui/components/pages/home/banner'
import BrandContent from '@/ui/components/pages/home/brand-content'
import PopularProductsCategories from '@/ui/components/pages/home/popular-products-categories'
// import RecommendProducts from '@/ui/components/pages/home/recommend-products'
import { WithPage } from '@/ui/components/shared/layout/hoc'

const brandContentData = {
  title: '关于我们',
  mainContent: '光学领域先驱',
  description: '我们持续挑战想象力的极限，怀揣不懈追求超凡的极大热情，为客户创造价值，并激发世界以全新的方式进行视觉体验。',
  targetLink: ABOUT_US_URL,
}

const Home = ({ productsCategories }: { productsCategories?: RecommendCategory[] }) => {
  return (
    <>
      <Banner />
      {/*<RecommendProducts />*/}
      <PopularProductsCategories productsCategories={productsCategories} />
      <BrandContent {...brandContentData} />
    </>
  )
}

export async function getServerSideProps() {
  try {
    const productsCategories = await getProductsCategories()
    return {
      props: {
        productsCategories,
      },
    }
  } catch {
    return { props: {} }
  }
}

export default WithPage(Home)
